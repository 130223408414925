import 'jquery'

$(function(){
    "use strict";

    let articles = $("article.news-item");
    let h1=0,
        h2=0;
    articles.each((i, a) => {
        if (h2 < h1) {
            $(a).addClass('col2-block-right');
            h2 = h2 + $(a).height() + 20;
        } else {
            $(a).addClass('col2-block-left');
            h1 = h1 + $(a).height() + 20;
        }
    });
    let aside = $("aside.news-item");
    if (h1 - h2 - 24 >= 350) {
        aside.addClass('col2-block-right');
        aside.height(Math.min(500, h1 - h2 - 24));
        aside.show();
    } else if (h2 - h1 - 24 >= 350) {
        aside.addClass('col2-block-left');
        aside.height(Math.min(500, h2 - h1 - 24));
        aside.show();
    }
});
